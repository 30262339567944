/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		var height = $( window ).height();

		var bLazy = new Blazy({ 
		offset: 3000,
		breakpoints: [{
		  width: 420 // max-width
		, src: 'data-src-small'
		}
		, {
		  width: 900 // max-width
		, src: 'data-src-medium'
		}, {
		  width: 1350 // max-width
		, src: 'data-src-large'
		}, {
		  width: 99999 // max-width
		, src: 'data-src-xl'
		}],

		});
		
		$('.gallery-images').slickLightbox({
			itemSelector: 'div.gallery-image > a',
			caption: 'caption'
		});
		  
		  	$(".banner-slider").slick({
			speed: 1000,
			autoplay: true,
			autoplaySpeed: 3000,
			touchThreshold: 20,
			useTransform: true,
			cssEase: 'cubic-bezier(0.420, 0.000, 0.580, 1.000)',
			prevArrow: '<div class="slick-arrow slick-prev" aria-label="previous"></div>',
			nextArrow: '<div class="slick-arrow slick-next" aria-label="next"></div>'
		});
		
		$('#quote-row').waypoint(function() {
			console.log('waypoint-fired');
		   $(this.element).addClass('fade-in');
		},{offset: height});

		$("a[href='#top']").click(function() {
			$("html, body").animate({ scrollTop: 0 }, "slow");
			return false;
		});

      },
      finalize: function() {
	/*	$('.banner-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
				var bLazy = new Blazy();
				var slideCount = nextSlide + 2;
				console.log(slideCount);
				
				if( slideCount > (slick.slideCount + 1) ) { 
					return;
				} else {
					nextSlideImg = document.querySelector('.slide-img-'+ slideCount);
					if ( nextSlideImg ) {
						if (nextSlideImg.classList.contains('b-loaded')) {
							//do nothing
						} else {
							bLazy.load( nextSlideImg );
						}
					}
				}
		}); */
		
		$(".navbar").on('affix.bs.affix', function () {
		//	$('.wrap').css('paddingTop', '0');​
		});
		
		$(".navbar").on('affix-top.bs.affix', function () {
	    //	$('.wrap').css('paddingTop', '60px');​
		});
		$(".navbar-collapse").on('show.bs.collapse', function () {
			$('.navbar-brand').css('display', 'none');​
		});
		$(".navbar-collapse").on('hide.bs.collapse', function () {
			$('.navbar-brand').css('display', 'block');​
		});
		
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
		$( '.ninja-forms-form' ).on( 'submitResponse', function( e, response ) {
			var errors = response.errors;
			if ( errors == false ) {
				console.log('submitResponse fired');
				ga('send', {
				  hitType: 'event',
				  eventCategory: 'Contact Form Submission',
				  eventAction: 'submit'
				});
			}
		});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
